import { Statistics } from 'components/Statistics';
import { useMemo } from 'react';
import { SectionHeading } from 'components/SectionHeading';
import { DashboardStatistics } from 'shared/types/dashboard';

type Props = {
  statistics?: DashboardStatistics;
};
export const AffiliateCustomerOperations = (props: any) => {
  const { upfrontTotal, totalSales } = props;

  const statisticsData = useMemo(
    () => [
      {
        title: 'Total Sales',
        value: totalSales
          ? `$${totalSales.toLocaleString('us-En', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : null,
        currency: 'USD',
      },
      {
        title: 'Total Upfront payments',
        value: upfrontTotal
          ? `$${upfrontTotal.toLocaleString('us-En', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : null,
        currency: 'USD',
      },
    ],
    [upfrontTotal, totalSales],
  );

  return (
    <>
      <SectionHeading
        sx={{
          color: '#fff',
        }}
        name="Your sales achievements"
      />
      <Statistics statistics={statisticsData} />
    </>
  );
};
