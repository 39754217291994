import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { InfoCard } from 'components/Cards/InfoCard';
import { defaultLineChartStyles } from 'components/Charts/styles';
import { SectionHeading } from 'components/SectionHeading';
import moment from 'moment';
import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { dateSx, sx, titleSx } from '../styles';
import { colors } from 'shared/constants/theme';
import { envConfig } from 'config/env';

const RoiStatistic = (props: any) => {
  const { lastMonth, color, sinceFirstContract, firstContractMonth, title } =
    props;

  return (
    <InfoCard
      styles={{
        height: { xl: '316px' },
      }}
      showDivider={false}
      showTime={false}
      info={title}
      subtitle={title}
      content={
        <>
          <Grid container>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography sx={titleSx} fontWeight={500}>
                Last Month
              </Typography>
              <Typography sx={dateSx} color="#828282">
                {moment().subtract('1', 'months').format('MMMM yyyy')}
              </Typography>
              {lastMonth !== null ? (
                <Typography fontWeight={500} sx={sx} color={color}>
                  {lastMonth > 0 ? '+' : ''}
                  {lastMonth.toLocaleString('en-US')}%
                </Typography>
              ) : (
                <Skeleton height={60} width={150} variant="rectangular" />
              )}
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography sx={titleSx} fontWeight={500}>
                Since Beginning
              </Typography>
              <Typography sx={dateSx} color="#828282">
                {moment(firstContractMonth).format('MMMM yyyy')}
              </Typography>
              {sinceFirstContract !== null ? (
                <Typography fontWeight={500} sx={sx} color={color}>
                  {sinceFirstContract > 0 ? '+' : ''}
                  {sinceFirstContract.toLocaleString('en-US')}%
                </Typography>
              ) : (
                <Skeleton height={60} variant="rectangular" width={150} />
              )}
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

const YearlyStatistic = (props: any) => {
  const { projectedYearly, title } = props;

  return (
    <InfoCard
      showDivider={false}
      showTime={false}
      info={title}
      subtitle={title}
      headerColor="#ffffff"
      infoIconColor="#ffffff"
      styles={{
        height: { md: '50%', lg: '50%', xl: '50%' },
        background: colors.lightGreen,
        border: 'unset',
      }}
      content={
        projectedYearly !== null ? (
          <Typography fontWeight={500} sx={{ ...sx, color: '#ffffff' }}>
            {projectedYearly > 0 ? '+' : ''}
            {projectedYearly}%
          </Typography>
        ) : (
          <Skeleton variant="rectangular" height={50} width={100} />
        )
      }
    />
  );
};

export const Performance = (props: any) => {
  const { statistics, chart } = props;

  const data = useMemo(
    () => ({
      labels: chart ? chart.map((c: any) => c.timestamp) : [],
      datasets: [
        {
          label: 'With Collateral',
          backgroundColor: '#87d3ad',
          borderColor: '#87d3ad',
          borderWidth: 1,
          borderRadius: 5,
          data: chart ? chart.map((i: any) => i.withCollateral) : [],
        },
        {
          label: 'Without Collateral',
          backgroundColor: '#0fba60',
          borderColor: '#0fba60',
          borderWidth: 1,
          borderRadius: 5,
          data: chart ? chart.map((i: any) => i.withoutCollateral) : [],
        },
      ],
    }),
    [chart],
  );

  const projectedStatistics = useMemo(
    () => [
      {
        projectedYearly: statistics
          ? statistics.projectedYearly.withCollateral
          : null,
        title: 'Projected Yearly with collateral',
      },
      {
        projectedYearly: statistics
          ? statistics.projectedYearly.withoutCollateral
          : null,
        title: 'Projected Yearly without collateral',
      },
    ],
    [statistics],
  );

  const calculatedStatistics = useMemo(
    () => [
      {
        lastMonth: statistics ? statistics.lastMonth.withCollateral : null,
        sinceFirstContract: statistics
          ? statistics.sinceFirstContract.withCollateral
          : null,
        color: colors.darkGreen,
        title: (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              columnGap: 1,
            }}
          >
            ROI With Collateral
            <Box
              sx={{
                height: '15px',
                width: '15px',
                bgcolor: colors.darkGreen,
                borderRadius: '50%',
              }}
            ></Box>
          </Box>
        ),
      },
      {
        lastMonth: statistics ? statistics.lastMonth.withoutCollateral : null,
        sinceFirstContract: statistics
          ? statistics.sinceFirstContract.withoutCollateral
          : null,
        color: 'secondary.main',
        title: (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              columnGap: 1,
            }}
          >
            ROI Without Collateral
            <Box
              sx={{
                height: '15px',
                width: '15px',
                backgroundColor: 'secondary.main',
                borderRadius: '50%',
              }}
            ></Box>
          </Box>
        ),
      },
    ],
    [statistics],
  );

  const firstContractMonth = useMemo(
    () => (statistics ? statistics.firstContractMonth : null),
    [statistics],
  );

  return (
    <>
      <SectionHeading
        sx={{
          marginTop: 8,
        }}
        name="Your performances"
      />
      <Grid marginTop={1} container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <InfoCard
            styles={{
              height: { xl: '100%', lg: '100%', md: '100%' },
            }}
            subtitle="Perfomance Per Month"
            linkText="How it's calculated?"
            link={`${envConfig.API_BASE_URL}/files/performance_calculation.pdf`}
            title=""
            fullHeight
            content={
              chart ? (
                <Bar
                  data={data}
                  options={{
                    maintainAspectRatio: false,
                    interaction: {
                      mode: 'index',
                      intersect: false,
                    },
                    scales: {
                      y: {
                        beginAtZero: true,
                      },
                      x: {
                        ticks: {
                          callback(tickValue: any) {
                            return moment(data.labels[tickValue]).format('MMM');
                          },
                        },
                      },
                    },
                    plugins: {
                      ...defaultLineChartStyles.plugins,
                      tooltip: {
                        ...defaultLineChartStyles.plugins.tooltip,
                        bodyColor(ctx): any {
                          if (ctx.tooltip) {
                            return ctx.tooltip.dataPoints[0].dataset
                              .backgroundColor;
                          }
                        },
                        displayColors: true,
                        callbacks: {
                          title: (item: any) => {
                            const label = item[0].label;

                            return moment(label).format('MMMM YYYY');
                          },
                          label: (item) => {
                            return `${item.dataset.label} ${item.formattedValue}%`;
                          },
                        },
                      },
                    },
                  }}
                />
              ) : (
                <Skeleton variant="rectangular" height={450} />
              )
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            display="flex"
            sx={{
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
                xl: 'row',
              },
            }}
            columnGap={2.2}
            rowGap={2.2}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              xl={7}
              display="flex"
              flexDirection="column"
              rowGap={2.2}
            >
              {calculatedStatistics.map((statistic) => (
                <RoiStatistic
                  {...statistic}
                  firstContractMonth={firstContractMonth}
                />
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              display="flex"
              flexDirection="column"
              rowGap={2.2}
            >
              {projectedStatistics.map((statistic) => (
                <YearlyStatistic {...statistic} />
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
